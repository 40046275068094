.institution-details1{
    margin-left: 5rem !important;
    margin-top: 2rem !important;
    color: #0080FF !important;
    font-size: "var(--font-size)";
    font-family: "var(--font-family)";
}
.institution-details2{
    margin-left: 5rem !important;
    margin-top: 0.8rem !important;
    color: #0080FF !important;
    font-size: "var(--font-size)";
    font-family: "var(--font-familt)";
}
.institution-details3{
    margin-left: 0.5rem !important;
    margin-top: 2rem !important;
    font-size: "var(--font-size)";
    font-family: "var(--font-familt)";
}
.institution-details4{
    margin-left: 0.5rem !important;
    margin-top: 0.8rem !important;
    margin-bottom: 1rem !important;
    font-size: "var(--font-size)";
    font-family: "var(--font-familt)";
}
.dashboard-username{
    font-family: sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 0.9rem !important;
    color: gray;
    margin-left: 5rem !important;
    margin-top: 2.5rem !important;
    font-size: "var(--font-size)";
    font-family: "var(--font-familt)";
}
.dashboard-password{
    font-family: sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 0.9rem !important;
    color: gray;
    margin-left: 7rem !important;
    margin-top: 2.5rem !important;
    font-size: "var(--font-size)";
    font-family: "var(--font-familt)";
}
.dashboard-accesslevel{
    font-family: sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 0.9rem !important;
    color: gray;
    margin-left: 6.6rem !important;
    margin-top: 2rem !important;
    margin-bottom: 0.5rem !important;
    width: 100px !important;
    font-size: "var(--font-size)";
    font-family: "var(--font-family)";
}
.icon-button{
    margin-left: 4.5rem !important;
    margin-top: 0.5rem !important;
}

.model-institution-text{
    margin-top: -20px !important;
    margin-left: -11.3rem !important;
    margin-bottom: 5px  !important;
    font-size: 0.9rem !important;
    color: #0080FF !important;
    font-size: "var(--font-size)";
}
.model-shortname-text{
    margin-left: -13.1rem !important;
    margin-bottom: 5px  !important;
    font-size: 0.9rem !important;
    color: #0080FF !important;
    font-size: "var(--font-size)";
}
.model-upload-text{
    margin-left: -12.5rem !important;
    margin-bottom: 5px  !important;
    font-size: 0.9rem !important;
    color: #0080FF !important;
    font-size: "var(--font-size)";
}
.file-upload{
    margin-left: 0.5rem !important;
    margin-top: 0.5rem !important;
}
.eye-icon {
    color: gray !important;
}

.username-input{
    height: 40px !important;
    width: 170px !important;
    margin-left: 20px !important;
    
}
.password-input{
    height: 40px !important;
    width: 200px !important;
}
.dropdown-select{
    width: 130px !important;
    text-align: left !important;
}

.delete-icon-rhs{
    height: 1.15rem !important;
}

.delete-icon-user{
    min-width: 2rem !important;
    margin-left: 0.3rem !important;
}

.view-icon-user{
    min-width: 2rem !important;
}

.view-icon-rhs{
    height: 1.3rem !important;
}
.close-icon-rhs{
    height: 0.8rem !important;
}

.edit-icon-rhs{
    width: 1.1rem !important;
    height: 1.3rem !important;
}

.edit-icon-user{
    min-width: 2rem !important;
    margin-left: 0.3rem !important;
}

.table-head{
    width: 480px !important;
    margin-left: 80px !important;
}

.table-text{
    margin-top: 0.8rem !important;
    font-size: 0.9rem !important;
    font-family: Arial, Helvetica, sans-serif !important;
}

.institution-table{
    width: 480px !important;
    margin-left: 80px !important;
}

.password-paper{
    margin-bottom: 0rem !important;
    padding-top: 0.9rem !important;
    margin-left: -8rem !important;
    color: gray !important;
}

.password-view-paper {
    margin-left: 20rem !important;
    height: 90px !important;
    width: 15rem !important;
    margin-bottom: 0.5rem !important;
}

.logo{
    width: 4rem !important;
    margin-left: 2.5rem !important;
    margin-top: 1.5rem !important;
}

.close-icon-new-institution{
    position: absolute !important;
    left: 25rem !important;
    top: 0.8rem !important;
    color: #64748B !important;
}

.add-institute-button{
    margin-left: 30px !important;
    margin-right: 20px !important;
    margin-bottom: 20px !important;
    width: 18rem !important;
    background-color: #0080FF !important;
}

.css-1ygcj2i-MuiTableCell-root {
    font-family: "sans-serif";
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.5rem;
    letter-spacing: 100 em;
    display: table-cell;
    vertical-align: center;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    text-align: center !important;
    padding: 10px !important;
    padding-left: 70px !important;
    color: rgba(0, 0, 0, 0.87);
}

