.dialog-style{
    font-family: var(--font-family);
    color: #1E293B;
    font-weight: 500;
}
.dialog-action{
    justify-content: center;
    padding-bottom: 20px;
}
.errorDialog-style{
    font-family: var(--font-family);
    color: #1E293B;
    width: 550px;
    height: 300px;
}
.yes-btn{
    left: -13% !important;
    border: "1px solid var(--common-color)" !important;
    color: "var(--common-color)" !important;
    font-family: arial !important;
    width: 84.25px !important;

}
.no-btn{
    left: -17% !important;
    margin-left: 1.5rem !important;
    background-color: "var(--common-color)" !important;
    font-family: arial !important;
    width: 84.25px !important;
}

.model-close-icon{
    position: absolute !important;
    right: 1rem !important;
    top: 0.8rem !important;
    color: 'var(--preferred-room-title-color)' !important;
}