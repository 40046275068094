.adduser-username{
    font-family: sans-serif !important;
    font-style: normal !important;
    margin-top: -20px !important;
    margin-left: -153px !important;
    margin-bottom: 5px  !important;
    font-size: 0.9rem !important;
    color: #0080FF !important;
}
.adduser-password{
    font-family: sans-serif !important;
    font-style: normal !important;
    margin-left: -158px !important;
    margin-bottom: 3px  !important;
    margin-top: -9px !important;
    font-size: 0.9rem !important;
    color: #0080FF !important;
}
.adduser-accesslevel{
    font-family: sans-serif !important;
    font-style: normal !important;
    font-size: 0.9rem !important;
    color: #0080FF !important;
    margin-left: -33px !important;
    margin-top: -2.5rem !important;
    margin-bottom: 0.6rem !important;
    width: 100px !important;
}
.adduser-username-input{
    width: 225px !important;
}
.adduser-password-input{
    width: 225px !important;
}
.adduser-dropdown{
    left: -1.8rem !important;
    margin-top: -6px !important;
    width: 230px !important;
    text-align: left !important;
}

.new-access-level{
    margin-left: 1rem !important;
    width: 10.7rem !important;
}

.close-icon-user{
    position: absolute !important;
    left: 25rem !important;
    top: 0.8rem !important;
    color: #64748B !important;
}

.add-user-form{
    max-width: 170px !important;
}

.user-button{
    margin-left: 20px !important; 
    margin-right: 20px !important;
    margin-bottom: 20px !important;
    width: 13.8rem !important;
    background-color: #0080FF !important;
}

.add-icon-textfield{
    margin-left: 0rem !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #fff;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px #FFF inset;
}