:root{
  --gantt-sidebar-header: #EFF6FF;
  --font-family: arial !important;
  --preferred-room-title-color: #64748B;
  --common-color: #1976D2;
  --font-size: 15px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


