.header{
    display: flex !important;
    width: 100% !important;
    box-shadow: 0 2px 2px 0 rgba(214, 212, 212, 0.2), 0 4px 4px 0 rgba(197, 194, 194, 0.19);
    position:fixed !important;
    top:0px !important;
    z-index: 999 !important;
}

.header-layout{
    background-color: #fff !important;
    display: flex;
    box-sizing: border-box;
    border-bottom: 1px solid rgb(240, 240, 240);
    height: 60px;
    width: 100%;
    padding-left:2rem;
    padding-right:1rem;
}

.header-layout__campusplanner{
    font-weight: 600 !important;
    color: #2b2b4e !important;
    flex-grow: 1;
    text-align: left;
}

.campusplanner-logo{
    position: absolute;
    height: 55px;
    top: 0.15rem;
    left: 1rem;
}

.header_icon{
    color: grey;
}

/* .header_logout{
    padding-left: 0.5rem;
} */

.logout-icon{
    left: 0.8rem;
}

.header-tabs {
    color: #8d95a5;
    text-decoration: none !important;
}

.header-tabs:hover {
    color: #8d95a5;
    background-color: #e8eaed;
}


.header-tabs--active{
    margin-top: 0.8rem !important;
    margin-right: 67rem !important;
    margin-bottom: 0.8rem !important;
    padding: 0.5rem 1rem;
    font-size: 18px !important;
    color: #3b4867;
    text-decoration: none;
}

.header-tabs--active:hover {
    color: #3b4867;
    background-color: #e8eaed;
}

.institution-partition{
    height: 35rem !important;
    width: 87rem !important;
    margin-top: 9.2rem !important;
    margin-left: 4rem !important;
}