.institutionname-text {
    margin-top: -20px !important;
    margin-left: -11.3rem !important;
    margin-bottom: 5px  !important;
    font-size: 0.9rem !important;
    color: #0080FF !important;
}

.shortname-text{
    margin-left: -13.1rem !important;
    margin-bottom: 5px  !important;
    font-size: 0.9rem !important;
    color: #0080FF !important;
}

.upload-text{
    margin-left: -12.5rem !important;
    margin-bottom: 5px  !important;
    font-size: 0.9rem !important;
    color: #0080FF !important;
}

.file-upload-input{
    margin-left: 0.5rem !important;
    margin-top: 0.5rem !important;
    width: 18rem !important;
}

.institution-name-field{
    width: 18rem !important;
}

.short-name-field{
    width: 18rem !important;
}

.close-icon-edit{
    position: absolute !important;
    left: 25rem !important;
    top: 0.8rem !important;
    color: #64748B !important;
}

.institute-edit-button{
    margin-left: 30px !important;
    margin-right: 20px !important;
    margin-bottom: 20px !important;
    width: 18rem !important;
    background-color: #0080FF !important;
}