.institution-partition{
    display: flex !important;
    width: 92.5% !important;
    height: 82% !important;
    position:fixed !important;
    top:-3rem !important;
    right: 4.5rem !important;
    left: -0.5rem !important
}

.institution-page {
    font-family: sans-serif !important;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.align-grid-items {
    align-items: center;
    justify-content: space-around;
}

.institution-content--button--selected {
    background: rgba(234, 230, 230, 0.878) !important;
    border-radius: 0px;
}

.institution-content--button {
    font: 500 14px 'sans-serif';
    color: #454e64;
    width: 100%;
    padding: 0px;
    height: 50px;
}

.institution-content--button:hover {
    background-color: #e8eaed;
    border-radius: 0px;
}

.institution-content--button:active,
.institution-content--button:focus {
    background: rgba(234, 230, 230, 0.878) !important;
    border-radius: 0px;
}

.edit-icon {
    height: 18px;
    width: 1.1rem !important;
}

.delete-icon{
    width: 1.1rem !important;
    height: 1.15rem !important;
}

.remove .change {
    visibility: hidden;
}

.remove:hover .change {
    visibility: visible;
}

.remove-box-icon {
    height: 1.3rem;
    color: #6b7280;
}

.institute-button{
    background-color: #0080FF !important;
    width: 12rem !important;
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
    margin-bottom: 1rem !important;
    margin-top: 1.5rem !important;
    text-transform: none !important;
}
.institute-button:hover{
    background-color: #0080FF !important;
}

.add-icon{
    margin-left: -1.2rem !important;
    margin-right: 0.5rem !important;
}

.add-row-text{
    margin-top: 1.2rem !important;
    font-size: 12px !important;
    font-size: "var(--font-size)";
}

.table-container {
    width: 100%;
    overflow-x: auto;
  }
  
.edit-icon-btn{
    min-width: 2rem !important;
    /* margin-left: -0.5rem !important; */
}

.delete-icon-btn{
    min-width: 2rem !important;
    margin-left: 0.1rem !important;
}