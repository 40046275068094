.login-card {
    border-radius: 70px !important;
    height: 600px !important;
    min-width: 410px !important;
    /* background-color: rgba(234, 230, 230, 0.878) !important; */
}

.login{
    position: absolute;
    top: 47%;
    left: 75%;
    transform: translate(-50%, -50%)
}

.signin-logo {
    position: absolute;
    height: 130px;
    top: 6rem;
}

.signin-text {
    font-size: 23px !important;
}

.eye-icon {
    color: #484646 !important;
}

.login-username{
    font-family: sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 0.9rem !important;
    line-height: 1rem !important;
    color: #000000;
    text-align: left;
    margin-bottom: 10px !important;
}

.login-password{
    font-family: sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 0.9rem !important;
    line-height: 1rem !important;
    color: #000000;
    text-align: left;
    margin-top: 28px !important;
}

.username-inputfield{
    width: 300px !important;
}

.password-inputfield{
    margin-top: 10px !important;
    width: 300px !important;
}

.loginButton{
    font-family: sans-serif !important;
    font-weight: bold !important;
    font-size: 15px !important;
    background-color: #0080FF !important;
  }

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid rgba(234, 230, 230, 0.878);
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px rgba(234, 230, 230, 0.878) inset;
}