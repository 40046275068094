.edituser-username{
    margin-top: -20px !important;
    margin-left: -153px !important;
    margin-bottom: 5px  !important;
    font-size: 0.9rem !important;
    color: #0080FF !important;
}
.edituser-password{
    margin-left: -158px !important;
    margin-bottom: 3px  !important;
    margin-top: -9px !important;
    font-size: 0.9rem !important;
    color: #0080FF !important;
}
.edituser-username-input{
    width: 225px !important;
}
.edituser-password-input{
    width: 225px !important;
}
.edituser-dropdown{
    left: -1.8rem !important;
    margin-top: -6px !important;
    width: 230px !important;
    text-align: left !important;
}
.edituser-accesslevel{
    font-family: sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 0.9rem !important;
    color: #0080FF !important;
    margin-left: -2rem !important;
    margin-top: -2.5rem !important;
    margin-bottom: 0.5rem !important;
    width: 100px !important;
}

.new-access-level{
    margin-left: 15px !important;
    width: 195px !important;
}

.edit-user-button{
    background-color: #0080FF !important;
    width: 14.5rem !important;
    margin-left: 0.5rem !important;
    text-transform: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #fff;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px #FFF inset;
}

.accesslevel-form{
    max-width: 170px !important;
}